import { DeviceUUID } from 'device-uuid'

export const refreshURL = '/token/admin'
export const loginURL = '/auth/admin/login'
export const logoutURL = '/users/logout'
export const signUpURL = '/user/signup'
const getApiHost = (type: 'user' | 'order' | 'task') => {
  if (window.location.host.includes(`pillowstudio.kr`)) {
    return `https://${type}.default.v3.susuda.pillowstudio.kr`
  } else {
    return `https://${type}.susuda.co.kr`
  }
}

export const API_URL = {
  user: process.env.NODE_ENV === 'development' ? '/user' : getApiHost('user'),
  order:
    process.env.NODE_ENV === 'development' ? '/order' : getApiHost('order'),
  task: process.env.NODE_ENV === 'development' ? '/task' : getApiHost('task'),
  dev: 'http://localhost:4000',
} as const

export const apiUrl = (type: keyof typeof API_URL, url: string) => {
  return API_URL[type] + url
}

export const device_info = new DeviceUUID().parse()

const dua = [
  device_info.language,
  device_info.platform,
  device_info.os,
  device_info.cpuCores,
  device_info.isAuthoritative,
  device_info.silkAccelerated,
  device_info.isKindleFire,
  device_info.isDesktop,
  device_info.isMobile,
  device_info.isTablet,
  device_info.isWindows,
  device_info.isLinux,
  device_info.isLinux64,
  device_info.isMac,
  device_info.isiPad,
  device_info.isiPhone,
  device_info.isiPod,
  device_info.isSmartTV,
  device_info.source,
  device_info.isTouchScreen,
]
export const device_uuid = device_info.hashMD5(dua.join(':'))
export type API_URL_KEYS = keyof typeof API_URL
